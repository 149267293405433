
<ul class="navbar-nav" *ngIf="(auth.isAuthenticated$ | async) === false">
    <li class="nav-item">
        <a [routerLink]="" class="nav-link active m-1 mr-lg-4" id="signup-link" (click)="signUpWithRedirect()">{{'signup' | translate}}</a>
    </li>
    <li class="nav-item">
        <button class="btn btn-primary m-1 m-lg-2" (click)="loginWithRedirect()">{{'login' | translate}}</button>
    </li>
</ul>
<div *ngIf="auth.user$ | async as user" class="nav-item dropdown">    
    <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
        <i class="fa fa-user fa-lg mr-1"></i>{{user.name}}
    </a>
    <div class="dropdown-menu p-1" aria-labelledby="userDropdown">
        <a *ngIf="supplierName" class="nav-link" [routerLink]="['/sup']">{{ supplierName }}</a> 
        <a *ngIf="isAdminUser" class="nav-link" [routerLink]="['/admin']">Admin</a>
        <button class="btn btn-danger btn-sm" (click)="logout()">{{'logout' | translate}}</button>  
    </div>
</div>