import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-supplier-page',
  templateUrl: './supplier-page.component.html',
  styleUrls: ['./supplier-page.component.scss']
})
export class SupplierPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
