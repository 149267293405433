import { Component, OnInit } from '@angular/core';
import {CampaignService} from '../../services/campaign.service'
import {Campaign} from '../../models/Campaign'

@Component({
  selector: 'app-supplier-campaigns',
  templateUrl: './supplier-campaigns.component.html',
  styleUrls: ['./supplier-campaigns.component.scss']
})
export class SupplierCampaignsComponent implements OnInit {

  campaigns: { c: Campaign, details: boolean, edit: boolean }[] = []
  addInProgress: boolean = false;

  constructor(private campaignService: CampaignService) { }

  ngOnInit(): void {
    this.loadCampaigns();
  }

  private loadCampaigns(){
    this.campaignService.getCampaigns().subscribe(
      (result: Campaign[]) => { 
        this.campaigns = result
          .sort((a,b) => {return new Date(b.start).getTime() - new Date(a.start).getTime()})
          .map((campaign:Campaign) => {
            campaign.status = this.campaignService.calculateStatus(campaign)
            return {c: campaign, details: false, edit: false}
          });
        console.log(this.campaigns)
      },
      (err) => { this.showError(err.message) }  
    )
  }

  campaignEdited(campaign: Campaign){
    this.loadCampaigns();
  }

  errorMessage: string = null
  private showError(message: string){
    this.errorMessage = message;
  } 
  clearError(){
    this.errorMessage = null
  }

}
