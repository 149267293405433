<div *ngIf="supplierInfoService.paymentAccountActive$ | async else onboarding"  class="card p-2">
    <h5 class="card-title">Payment account</h5>
    <p>Your Stripe accout is configured correctly.</p>
    <button class="btn btn-primary" (click)="visitDashboard()" >Visit your Stripe dashboard</button>
    <ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="true" (closed)="clearError()">{{errorMessage}}</ngb-alert>
</div>
<ng-template #onboarding>
    <div class="card p-2">
        <h5 class="card-title">Payment account</h5>
        <p>Payments in AppHoc are handled by <a href="https://stripe.com/" target="_blank">Stripe</a></p> 
        <p>To receive payments, please configure your Stripe account</p>
        <button class="btn btn-primary" (click)="startOnboarding()" >Set up account</button>
        <ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="true" (closed)="clearError()">{{errorMessage}}</ngb-alert>
    </div>
</ng-template>

