import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-supplier-registration-page',
  templateUrl: './supplier-registration-page.component.html',
  styleUrls: ['./supplier-registration-page.component.scss']
})
export class SupplierRegistrationPageComponent implements OnInit {
  
  constructor() {  }

  ngOnInit(): void {
  }

  

}
