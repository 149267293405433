<div class="container profile-container">
    <div class="row">
        <div class="col">
            <form [formGroup]="profileForm" (ngSubmit)="save()">
                <div class="form-group">
                    <label for="nameInput">{{'profile.name' | translate}}</label>
                    <input class="form-control" [class.is-invalid]="name.invalid && (name.dirty || name.touched)" type="text" name="nameInput" formControlName="name"/>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)">
                        <small *ngIf="name.errors.required" class="form-control-feedback text-danger">Name is required</small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="usernameInput">{{'profile.user' | translate}}</label>
                    <input class="form-control" [class.is-invalid]="userName.invalid && (userName.dirty || userName.touched)" type="email" name="usernameInput" formControlName="userName"/>
                    <div *ngIf="userName.invalid && (userName.dirty || userName.touched)">
                        <small *ngIf="userName.errors.required" class="form-control-feedback text-danger">User is required</small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="addressInput">{{'profile.address' | translate}}</label>
                    <textarea class="form-control" name="addressInput" formControlName="address" rows="3"></textarea>   
                </div>
                <div class="form-group">
                    <label for="websiteInput">{{'profile.website' | translate}}</label>
                    <input class="form-control" type="text" name="websiteInput" formControlName="website"/>
                </div>
                <div class="form-group">
                    <label for="descriptionInput">{{'profile.description' | translate}}</label>
                    <textarea class="form-control" type="text" name="descriptionInput" rows="4" formControlName="description"></textarea>
                </div>
                <ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="true" (closed)="clearError()">{{errorMessage}}</ngb-alert>
                <div class="buttons">
                    <button class="btn btn-primary" type="button" (click)="reset()">{{'reset' | translate}}</button> 
                    <button class="btn btn-primary" type="submit">{{'save' | translate}}</button>                    
                </div>     
            </form>
        </div>
    </div>
</div>

