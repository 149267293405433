import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs'

import {LogEntryService} from './../../services/log-entry.service'
import {LogEntry} from './../../models/LogEntry'
import {LogQueryParameters} from './../../models/LogQueryParameters'

@Component({
  selector: 'app-admin-log',
  templateUrl: './admin-log.component.html',
  styleUrls: ['./admin-log.component.scss']
})
export class AdminLogComponent implements OnInit {
  logEntries: LogEntry[]
  parameterSetter: Subject<{parameter: string, value: string }> = new Subject<{parameter: string, value: string }>();

  constructor(private service: LogEntryService) { }

  ngOnInit(): void {
    
  }

  onNewQuery(queryParameters: LogQueryParameters){
    this.clearError()
    this.service.getLogEntries(queryParameters).subscribe(
      entries => {
        console.log(entries.length)
        console.log(entries)
        this.logEntries = entries
      },
      err => this.showError(err.message)      
    )
  }

  setQueryParameter(parameter: string, value: string){
      this.parameterSetter.next({parameter: parameter, value: value});
  }

  errorMessage: string = null
  private showError(message: string){
    this.errorMessage = message;
  } 
  clearError(){
    this.errorMessage = null
  }

}
