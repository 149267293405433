<div class="navbar navbar-expand-lg navbar-light bg-white sticky-top my-navbar" role="navigation">
    <a href="#" class="navbar-brand">
        <img src="assets/img/apphoc-logo.png" class="navbar-logo ml-auto mr-auto"/>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      style="border-width: 0px;"
      data-toggle="collapse"
      data-target="#navbarContent"
      aria-controls="navbarContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
        <img src="assets/img/ic-navbar-expand.svg" />
    </button>

    <div class="collapse navbar-collapse bg-white" id="navbarContent">
        <ul class="navbar-nav mx-auto">
            <li class="nav-item">
                <a routerLink="/lp/sup" class="btn m-1 m-lg-2" [ngClass]="{'btn-outline-dark': role == 'cust', 'btn-primary': role == 'sup'}" type="button">{{texts.supplierLink}}</a>
            </li>
            <li class="nav-item">
                <a routerLink="/lp/cust"  class="btn m-1 m-lg-2" [ngClass]="{'btn-outline-dark': role == 'sup', 'btn-primary': role == 'cust'}" type="button" >{{texts.customerLink}}</a>
            </li>
        </ul>
        <app-auth-nav class="ml-auto"></app-auth-nav>
    </div>    
</div>

<!-- Main image area -->
<!-- MOBILE VERSION -->
<div class="container-fluid d-lg-none title-area-mobile">
    <div class="row" style="height: 100px;height: 10vw;"></div>
    <div class="row text-center">
        <div class="col">            
            <h2><span style="color:black">Your </span>needs.</h2>
            <h2><span style="color:black">Your </span>day.</h2>
            <h2 style="margin-bottom: 1em;"><span style="color:black">Your </span>way.</h2>
            <p class="blur">{{texts.headerParagraph}}</p>
            <a routerLink="/register"><button type="button" class="btn btn-primary btn-lg" >{{texts.headerButton}}</button></a>
        </div>
    </div>
</div>
<!-- LARGE SCREEn VERSION -->
<div class="container-fluid d-none d-lg-block title-area-lg">
    <div style="display: flex;flex-direction: row-reverse;padding-top: 18vw;">
        <div>
            <ul style="list-style-type: none;">
                <li><a href="#"><img class="social m-1" src="assets/img/pinterest.png"/></a> </li>
                <li><a href="#"><img class="social m-1" src="assets/img/facebook.png"/></a></li>
                <li><a href="#"><img class="social m-1" src="assets/img/instagram.png"/></a></li>
            </ul>
        </div>
        <div>            
            <h1><span style="color:#444444">Your </span>needs.</h1>
            <h1><span style="color:#444444">Your </span>day.</h1>
            <h1><span style="color:#444444">Your </span>way.</h1>
            <p style="max-width: 25vw;">{{texts.headerParagraph}}</p>            
            <a routerLink="/register"><button type="button" class="btn btn-primary btn-lg">{{texts.headerButton}}</button></a>
        </div>
    
    </div>
    
    
    <div class="row" style="height: 18vw;"></div>
    <div class="row">
        <div class="col-6 col-xl-8">            
        </div>
        <div class="col-5 col-xl-3">
            
        </div>
        
    </div>
</div>


<!--- Bullet points -->
<div class="container container-width bullet-point-list">
    <div class="row row-cols-1 row-cols-lg-2 row-cols-xl-4">
        <div class="col">
            <h4 class="card-number">01</h4>
            <h2 class="my-card-title">{{texts.bulletpoint1.title}}</h2>
            <p>{{texts.bulletpoint1.paragraph}}</p>
        </div>
        <div class="col">
            <h4 class="card-number">02</h4>
            <h2 class="my-card-title">{{texts.bulletpoint2.title}}</h2>
            <p>{{texts.bulletpoint2.paragraph}}</p>
        </div>
        <div class="col">
            <h4 class="card-number">03</h4>
            <h2 class="my-card-title">{{texts.bulletpoint3.title}}</h2>
            <p>{{texts.bulletpoint3.paragraph}}</p>
        </div>
        <div class="col">
            <h4 class="card-number">04</h4>
            <h2 class="my-card-title">{{texts.bulletpoint4.title}}</h2>
            <p>{{texts.bulletpoint4.paragraph}}</p>
        </div>
    </div>
</div>


<!--- Carousel - MOBILE VERSION -->
<div class="container-fluid d-block d-lg-none p-0">
    <div class="carousel-mobile">
        <img class="img-fluid screen-left" src="assets/img/{{carouselImageId(-1)}}.png" />
        <img class="img-fluid screen-center" src="assets/img/{{carouselImageId(0)}}.png" />
        <img class="img-fluid screen-right" src="assets/img/{{carouselImageId(1)}}.png" />        
    </div>
</div>
<div class="container container-width d-block d-lg-none carousel-controller-mobile">
    <div class="row">
        <div class="col">           
            <a (click)="moveCarouselRight()"><img class="carousel-btn" src="assets/img/btn-left.png" /></a> 
        </div>  
        <div class="col">
            <h3 style="color: white; align-self: center; margin-top: 10px;">{{carouselPosition + 1 | number:'2.0'}}/<span style="font-size: 0.8em;">{{carouselCount | number:'2.0'}}</span></h3>
        </div>
        <div class="col">
            <a class="float-right" (click)="moveCarouselLeft()"><img class="carousel-btn" src="assets/img/btn-right.png" /></a> 
        </div>
    </div>
    <hr style="border-top: 1px solid rgba(255,255,255,0.2);">
    <div class="row">
        <div class="col">
            <h5 style="color: white; font-weight: bold;">{{carouselTitle}}</h5>
            <p style="color: white;font-size: smaller;">{{carouselParagraph}}</p>
        </div>
    </div>
</div>

<!--- Carousel - LARGE SCREEN VERSION -->
<div class="container d-none d-lg-block container-width" style="margin-top: 10vh;">
<div class="carousel-lg"> <!-- flex row-reverse: fill with items from right to left-->
    <img class="image3" src="assets/img/{{carouselImageId(2)}}.png"  />
    <img class="image2" src="assets/img/{{carouselImageId(1)}}.png" />  
    <img class="image1" src="assets/img/{{carouselImageId(0)}}.png"/>      
    <div class="carousel-lg-controller">
        <div style="display: flex; flex-direction: row;">
            <h3 style="color: white; align-self: center; margin-top: 10px;flex-grow: 1;">{{carouselPosition + 1 | number:'2.0'}}/<span style="font-size: 0.8em;">{{carouselCount | number:'2.0'}}</span></h3>
            <a (click)="moveCarouselRight()"><img class="carousel-btn" src="assets/img/btn-left.png" /></a> 
            <a (click)="moveCarouselLeft()"><img class="carousel-btn" src="assets/img/btn-right.png" /></a> 
        </div>
        <hr style="border-top: 1px solid rgba(255,255,255,0.2);">
        <h5 style="color: white;">{{carouselTitle}}</h5>
        <p style="color: white;">{{carouselParagraph}}</p>
    </div>
</div>
</div>

<!--- Centered text -->
<div class="container-fluid text-center bg-city-top">
    <div class="row">
        <div class="col">
            <h3 id="block-title" class="mx-auto" style="margin-top: 10vh;">{{texts.block.title}}</h3>
            <p id="block-paragraph" class="mx-auto">{{texts.block.paragraph}}</p>
            <p class="faq-title">{{texts.faqTitle}}</p>
        </div>
    </div>
</div>

<!-- FAQ -->
<div class="container-fluid bg-city-middle">
    <div class="faqList">
        <div class="faq" *ngFor="let faq of texts.faqs" >
            <div class="question" (click)="faq.expanded = !faq.expanded" >
                <p>{{faq.question}}</p>
                <img *ngIf="!faq.expanded" src="assets/img/ic_expand.svg" />
                <img *ngIf="faq.expanded" src="assets/img/ic_collapse.svg"  />    
            </div>            
        
            <div class="answer">
                <p *ngIf="faq.expanded" class="answer">{{faq.answer}}</p>            
            </div>
        </div>
    </div>    
</div>
<div class="container-fluid footer"> <!-- Bottom of faq -->
    <div class="row">
        <div class="col p-0">
            <img class="faq-bottom d-block d-lg-none" src="assets/img/city-middle-mobile.png" >
            <img class="faq-bottom d-none d-lg-block" src="assets/img/city-middle.png" >            
        </div>
    </div>
</div> 
<!-- MOBILE FOOTER -->
<div class="container-fluid d-block d-lg-none footer">
    <div class="row">
        <div class="col">
            <img class="footer-image" src="assets/img/apphoc-logo.png">
        </div>  
        <div class="col">
            <img class="footer-image" src="assets/img/download-android.png" >
            <img class="footer-image" src="assets/img/download-ios.png" >
        </div>        
    </div>
    <div class="row row-cols-2 row-cols-lg-1 top-buffer">
        <div class="col text-left">
            <a routerLink="/lp/sup"><h4>{{texts.supplierLink}}</h4></a>
        </div>
        <div class="col text-right">
            <a routerLink="/lp/sup"><h4>{{texts.supplierLink}}</h4></a>
        </div>
    </div>
    <hr>
    <div class="row">    
        <div class="col text-left">
            <p>&copy;2020 apphoc</p>
        </div>
        <div class="col text-right">
            <a class="link" routerLink="/impressum">Impressum und Datenschutz</a>
        </div>
    </div>
    <div class="row">
        <div class="col-2">
            <a href="#"><img class="social" src="assets/img/pinterest.png"/></a>
        </div>
        <div class="col-2">
            <a href="#"><img class="social" src="assets/img/facebook.png"/></a>
        </div>
        <div class="col-2">
            <a href="#"><img class="social" src="assets/img/instagram.png"/></a>
        </div>
    </div>
</div>

<!-- LG FOOTER -->
<div class="container-fluid d-none d-lg-block footer">
    <div class="row">
        <div class="col-1"></div>
        <div class="col">
            <img src="assets/img/apphoc-logo.png" >
            <img src="assets/img/download-android.png" >
            <img src="assets/img/download-ios.png" >
        </div>
        <div class="col text-right">
            <a routerLink="/lp/sup"><h4>{{texts.supplierLink}}</h4></a>
            <a routerLink="/lp/cus"><h4>{{texts.customerLink}}</h4></a> 
        </div>
        <div class="col-1"></div>
    </div>
    <hr>
    <div class="row">    
        <div class="col-8">
            <div style="display: flex; flex-direction: row;">
                <p class="link">&copy;2020 apphoc</p>
                <a class="link" routerLink="/impressum">Impressum und Datenschutz</a>
            </div>
        </div>
        <div class="col-4">
            <div style="display: flex; flex-direction: row-reverse;">
                <a href="#"><img class="social" src="assets/img/pinterest.png"/></a>
                <a href="#"><img class="social" src="assets/img/facebook.png"/></a>
                <a href="#"><img class="social" src="assets/img/instagram.png"/></a>
            </div>
        </div>
    </div>
</div>