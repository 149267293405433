<div class="details-container">
    <div class="details-line">
        <p class="caption">{{'campaigns.status' | translate}}:</p>
        <span class="value status">
            <a *ngIf="campaign.status!='finished'" type="button" class="btn btn-outline-primary" (click)="toggleEnabled()">
                <span *ngIf="campaign.enabled" class="fa fa-pause"></span>
                <span *ngIf="!campaign.enabled" class="fa fa-play"></span>
            </a>
            <p>{{'campaigns.status' + campaign.status | translate}}</p>            
        </span> 
    </div>
    <div class="details-line">
        <p class="caption">{{'campaigns.requests' | translate}}:</p>
        <p class="value">{{requestCount}}</p>
    </div>
    <div class="details-line">
        <p class="caption">{{'campaigns.appointments' | translate}}:</p>
        <p class="value">{{appointments.length}}</p>
    </div>
    <div class="details-line">
        <p class="caption">{{'campaigns.totalSum' | translate}}:</p> 
        <p class="value">{{totalSum | currency: 'EUR'}}</p>
    </div>
</div>


