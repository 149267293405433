import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Campaign } from '../models/Campaign';
import { SupplierInfoService } from './supplier-info.service'

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  private baseUrl = '/api/campaign'
  
  constructor(
    private http: HttpClient,
    private supplierInfoService: SupplierInfoService
  ) { }



  createCampaign(c: Campaign ): Observable<Campaign>{
    var formattedCampaign: Campaign = {
      name: c.name,
      start: this.formatDate(c.start),
      end: this.formatDate(c.end),
      services: c.services,
      enabled: c.enabled
    }

    console.log(formattedCampaign)

    return this.http.post<Campaign>(this.baseUrl, formattedCampaign)
  }

  updateCampaign(c: Campaign): Observable<Campaign>{
    var formattedCampaign: Campaign = {
      id: c.id,
      supplierId: c.supplierId,
      name: c.name,
      start: this.formatDate(new Date(c.start)),
      end: this.formatDate(new Date(c.end)),
      services: c.services,
      enabled: c.enabled
    }

    return this.http.put<Campaign>(`${this.baseUrl}/${c.id}`, formattedCampaign)
  }

  getCampaigns(): Observable<Campaign[]> {
    var params: HttpParams = new HttpParams().set('supplier', this.supplierInfoService.supplierId)
    return this.http.get<Campaign[]>(this.baseUrl, {params: params})
  }

  getCampaign(id: string): Observable<Campaign> {
    return this.http.get<Campaign>(`${this.baseUrl}/${id}`)
  }

  private formatDate(d: Date): string{
    var pad = function(num: number){
      return String(num).padStart(2,'0')
    }
    let offset = d.getTimezoneOffset()
    let sign = (offset <= 0) ? '+' : '-'
    return `${d.getFullYear()}-${pad(d.getMonth()+1)}-${pad(d.getDate())}T${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}${sign}${pad(Math.abs(offset)/60)}:${pad(offset%60)}`      
  }

  calculateStatus(campaign: Campaign){
    if(!campaign)
      return '';
    if(new Date(campaign.end).getTime() < new Date().getTime())
      return 'finished';
    if(!campaign.enabled)
      return 'disabled';
    if(new Date(campaign.start).getTime() > new Date().getTime())
      return 'planned';
    
    return 'active'
  }


}
