import { Component, OnInit } from '@angular/core';

import { SupplierInfoService } from './../../services/supplier-info.service'
import { ServiceCollectionService } from './../../services/service-collection.service'
import { Service } from '../../models/Service'

@Component({
  selector: 'app-supplier-services',
  templateUrl: './supplier-services.component.html',
  styleUrls: ['./supplier-services.component.scss'  ]
})
export class SupplierServicesComponent implements OnInit {
  public servicesToAdd: {selected: boolean, price: number, service: Service}[] = []  
  public serviceEntries: {service: string, caption: string, price: number, originalPrice: number, edit: boolean}[] = []

  addInProgress: boolean = false
  selectedGroup: string = null

  constructor(public supplierInfoService: SupplierInfoService, public serviceCollection: ServiceCollectionService) { }

  ngOnInit(): void {
    this.supplierInfoService.serviceEntries$.subscribe((serviceEntries) => { 
      this.serviceEntries = serviceEntries.map(se => { 
        return { service: se.serviceName, caption: se.serviceCaption, price: se.defaultPrice, originalPrice: se.defaultPrice, edit: false }
        });
        this.refreshServicesToAdd(); // make sure new services are no longer in the Add list      
      })
  }

  onGroupSelected(groupName: string){
    this.selectedGroup = groupName;
    this.refreshServicesToAdd()
  }

  refreshServicesToAdd(){
    if(this.selectedGroup == null)
      return

    this.servicesToAdd = this.serviceCollection.GetServicesForGroup(this.selectedGroup)
      .filter(service => this.serviceEntries.findIndex(se => se.service == service.name) < 0) // findIndex = -1 when not found
      .map(service => {                 
        return {selected: false, price: null, service: service};
      });
  }

  save(){
    this.clearError()
    console.log(this.servicesToAdd);
    this.supplierInfoService.addServices(
      this.servicesToAdd.filter(s => (s.price || s.price === 0)).map(s =>{ return {name: s.service.name, price: s.price}})
    ).subscribe(
      (_) => { this.addInProgress = false },
      (err) => this.showError(err.message)
    );
  }

  deleteService(serviceFullName: string){
    this.clearError()
    this.supplierInfoService.deleteService(serviceFullName).subscribe(
      (_) => {},
      (err) => this.showError(err.message)
    );
  }

  updateServicePrice(serviceFullName: string){
    this.clearError()
    let newPrice = this.serviceEntries.find(s => s.service == serviceFullName).price
    this.supplierInfoService.updateServicePrice(serviceFullName, newPrice).subscribe(
      (_) => {},
      (err) => this.showError(err.message)
    );
  }

  errorMessage: string = null
  private showError(message: string){
    this.errorMessage = message;
  } 
  clearError(){
    this.errorMessage = null
  }

}
