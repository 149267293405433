<div class="container new-campaign-container">
    <div class="row">
        <div class="col-md">
            <h5>{{'campaigns.newCampaign' | translate}}</h5>
            <form [formGroup]="dataForm" (ngSubmit)="save()">
                <div class="form-group">
                    <label for="name-input">{{'campaigns.name' | translate}}</label>
                    <input class="form-control" type="text" 
                        name="name-input" formControlName="name" 
                        [class.is-invalid]="name.invalid && (name.dirty || name.touched)" />
                    <div *ngIf="name.invalid && (name.dirty || name.touched)">
                        <small *ngIf="name.errors.required" class="form-control-feedback text-danger">Name is required</small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="date-input">{{'campaigns.date' | translate}}</label>
                    <div class="input-group">
                        <input class="form-control" placeholder="dd mm yyyy" name="date-input" 
                                formControlName="date" ngbDatepicker #d1="ngbDatepicker" >
                        <div class="input-group-append">
                            <button class="btn btn-outline-primary calendar" (click)="d1.toggle()" type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="times">
                    <div class="form-group">
                        <label for="startInput">{{'campaigns.start' | translate}}</label>
                        <ngb-timepicker formControlName="startTime" [minuteStep]="15" #startInput  ></ngb-timepicker>
                    </div>
                    <div class="form-group">
                        <label for="endInput">{{'campaigns.end' | translate}}</label>
                        <ngb-timepicker formControlName="endTime" [minuteStep]="15" #endInput ></ngb-timepicker>
                    </div>
                </div>
                <div class="form-group">
                    <label for="servicesInput">{{'campaigns.services' | translate}}</label>
                    <select name="servicesInput" [(ngModel)]="selectServices"  [ngModelOptions]="{standalone: true}" class="form-control">
                        <option value="false">{{'campaigns.allServices' | translate}}</option>
                        <option value="true">{{'campaigns.selectedServices' | translate}}</option>                        
                    </select> 
                    <div class="select-services" *ngIf="selectServices">
                        <div class="service" *ngFor="let service of services">
                            <input type="checkbox" [(ngModel)]="service.selected" [ngModelOptions]="{standalone: true}" [id]="service.service">
                            <label [for]="service.service">{{service.caption}}</label>
                        </div>
                    </div>
                </div>
                <ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="true" (closed)="clearError()">{{errorMessage}}</ngb-alert>
                <div class="buttons">
                    <button class="btn btn-primary" type="button" (click)="cancel()">{{'cancel' | translate}}</button>
                    <button class="btn btn-primary" type="submit">{{'save' | translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
