<div class="container">
    <div class="row">
        <div class="col-md">
            <h3>Enter the data for your business</h3>
            <form [formGroup]="dataForm" (ngSubmit)="save()">
                <div class="form-group">
                    <label for="nameInput">Name</label>
                    <input class="form-control" [class.is-invalid]="name.invalid && (name.dirty || name.touched)" type="text" name="nameInput" formControlName="name"/>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)">
                        <small *ngIf="name.errors.required" class="form-control-feedback text-danger">Name is required</small>
                    </div>
                </div>
                <div class="form-group">
                    <label for="addressInput">Address</label>
                    <textarea class="form-control" name="addressInput" formControlName="address" rows="3"></textarea> 
                </div>
                <ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="true" (closed)="clearError()">{{errorMessage}}</ngb-alert>
                <div class="text-right">
                    <button class="btn btn-primary" type="submit">Next</button>
                </div>
            </form>
        </div>
    </div>
</div>
