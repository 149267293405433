import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { AuthService} from '@auth0/auth0-angular'
import { DOCUMENT } from '@angular/common';

import { SupplierProfile } from '../../models/SupplierProfile'
import { SupplierInfoService} from './../../services/supplier-info.service'

@Component({
  selector: 'app-auth-nav',
  templateUrl: './auth-nav.component.html',
  styleUrls: ['./auth-nav.component.scss']
})
export class AuthNavComponent implements OnInit {
  private admins: string[] = ['robrecht.scheepers@gmail.com']
  
  supplierId: string = null
  supplierName: string = null
  isAdminUser: Boolean = false

  constructor(
    public auth: AuthService, 
    public supplierInfoService: SupplierInfoService, 
    private http: HttpClient,
    @Inject(DOCUMENT) private doc: Document) { }

  ngOnInit(): void {    
    this.auth.user$.subscribe((user) => {
      if(user == null)
        this.isAdminUser = false;
      else
        this.isAdminUser = this.admins.includes(user.email);
    })
    this.supplierInfoService.supplierProfile$.subscribe(
      (supplierInfo) => this.supplierName = supplierInfo?.name
    )

  }

  canUserAccessAdminArea(userName: string): Boolean{
      return this.admins.includes(userName)
  }

  loginWithRedirect(): void {
    this.auth.loginWithRedirect();
  }

  signUpWithRedirect(): void {
    this.auth.loginWithRedirect({ screen_hint: 'signup' });
  }

  logout(): void {
    this.auth.logout({ returnTo: this.doc.location.origin });
  }

}
