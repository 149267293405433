import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http'
import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap'
import {Observable} from 'rxjs'

import {LogEntry} from './../models/LogEntry'
import {LogQueryParameters} from './../models/LogQueryParameters'

@Injectable({
  providedIn: 'root'
})
export class LogEntryService {
  private baseUrl = '/api/logentry'

  constructor(private http: HttpClient, private dateFormatter: NgbDateParserFormatter) { }

  getLogEntries(parameters: LogQueryParameters): Observable<LogEntry[]>{
    let params = new HttpParams()
                      .set('level', parameters.level.toString())
                      .set('request', parameters.request)
                      .set('user', parameters.user)
                      .set('source', parameters.source)
                      .set('entity', parameters.entity)
                      .set('message', parameters.message)
                      .set('exception', parameters.exception)
                      .set('datefrom', this.dateFormatter.format(parameters.dateFrom))
                      .set('dateuntil', this.dateFormatter.format(parameters.dateUntil));

    console.log(params);

    return this.http.get<LogEntry[]>(this.baseUrl, {params: params});
  }
}
