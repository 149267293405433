import { Component, OnInit } from '@angular/core';
import { ImageResult, Options } from 'ngx-image2dataurl';
import { SupplierInfoService } from 'src/app/services/supplier-info.service';

@Component({
  selector: 'app-supplier-images',
  templateUrl: './supplier-images.component.html',
  styleUrls: ['./supplier-images.component.scss']
})
export class SupplierImagesComponent implements OnInit {

  constructor(public supplierInfoService: SupplierInfoService) { }

  logoOptions: Options = {
    resize: {
      maxHeight: 200,
      maxWidth: 200
    }
  }

  profileImageOptions: Options = {
    resize: {
      maxHeight: 400,
      maxWidth: 800
    }
  }

  ngOnInit(): void {
  }


  onLogoSelected(imageResult: ImageResult){
    if(imageResult.error){
      console.log(imageResult.error);
      return;
    }

    const imageDataUrl = 
      imageResult.resized?.dataURL || imageResult.dataURL;
    
    this.supplierInfoService.updateLogo(this.datatUrlToBlob(imageDataUrl))    
  }

  onProfileImageSelected(imageResult: ImageResult){
    if(imageResult.error){
      console.log(imageResult.error);
      return;
    }

    const imageDataUrl = 
      imageResult.resized?.dataURL || imageResult.dataURL;
    
    this.supplierInfoService.updateProfileImage(this.datatUrlToBlob(imageDataUrl))    
  }

  // tribute: https://www.geeksforgeeks.org/how-to-convert-data-uri-to-file-then-append-to-formdata/
  private datatUrlToBlob(inputURI: string): Blob {
    
    var binaryVal;

    // mime extension extraction
    var inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0];

    // Extract remaining part of URL and convert it to binary value
    if (inputURI.split(',')[0].indexOf('base64') >= 0)
        binaryVal = atob(inputURI.split(',')[1]);
    // Decoding of base64 encoded string
    else
        binaryVal = unescape(inputURI.split(',')[1]);

    // Computation of new string in which hexadecimal
    // escape sequences are replaced by the character 
    // it represents

    // Store the bytes of the string to a typed array
    var max = binaryVal.length
    var blobArray = new Uint8Array(max);
    for (var i = 0; i < max; i++) {
        blobArray[i] = binaryVal.charCodeAt(i);
    }

    return new Blob([blobArray], { type: inputMIME });
  }

}
