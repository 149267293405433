<div class="navbar navbar-expand-lg navbar-light bg-white sticky-top my-navbar" role="navigation">
    <a href="#" class="navbar-brand">
        <img src="assets/img/apphoc-logo.png" class="navbar-logo ml-auto mr-auto"/>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      style="border-width: 0px;"
      data-toggle="collapse"
      data-target="#navbarContent"
      aria-controls="navbarContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
        <img src="assets/img/ic-navbar-expand.svg" />
    </button>

    <div class="collapse navbar-collapse bg-white" id="navbarContent">
        <ul class="navbar-nav ml-auto">
            <li [routerLinkActive]="['active']" class="nav-item">
                <a class="nav-link" [routerLink]="['dashboard']" >{{'navbar.dashboard' | translate}}</a>    
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">                
                <a class="nav-link" [routerLink]="['profile']" >{{'navbar.profile' | translate}}</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">                
                <a class="nav-link" [routerLink]="['images']" >{{'navbar.images' | translate}}</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">
                <a class="nav-link" [routerLink]="['services']" >{{'navbar.services' | translate}}</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">
                <a class="nav-link" [routerLink]="['openinghours']" >{{'navbar.openingHours' | translate}}</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">
                <a class="nav-link" [routerLink]="['campaigns']" >{{'navbar.campaigns' | translate}}</a>
            </li>
            <li [routerLinkActive]="['active']" class="nav-item">
                <a class="nav-link" [routerLink]="['history']" >{{'navbar.history' | translate}}</a>
            </li>
        </ul>
        <app-auth-nav class="ml-auto"></app-auth-nav>
    </div>    
</div>
<div style="height: 3vh;"></div>

<router-outlet></router-outlet>