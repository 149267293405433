<table class="table">
    <thead>
        <th scope="col">Date</th>
        <th scope="col">Time</th>
        <th scope="col">Customer</th>
        <th scope="col">Service</th>
        <th scope="col">Price</th>
        <th scope="col">Status</th>                    
    </thead>
    <tbody>
        <tr *ngFor="let appointment of appointments">
            <td>{{appointment.time | date : 'dd. MMM.'}}</td>
            <td>{{appointment.time | date : 'HH:mm'}}</td>
            <td>{{appointment.customer}}</td>
            <td>{{appointment.serviceCaption}}</td>
            <td>{{appointment.price | currency }}</td>
            <td>{{appointment.statusCaption}}</td>
        </tr>
    </tbody>
</table>
