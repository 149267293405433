<div class="card mt-2">
    <div class="card-body">
        
        <form (ngSubmit)="onSubmit()">            
            <button class="btn btn-outline-secondary float-right" (click)="onSubmit()" type="button">
                <i class="fa fa-refresh"></i>
            </button>
            <div class="form-group">
                <label for="dateFrom">Start date</label>
                <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="dateFrom" [(ngModel)]="queryParameters.dateFrom"
                            ngbDatepicker #d1="ngbDatepicker" (ngModelChange)=onSubmit()>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="dateUntil">End date</label>
                <div class="input-group">
                    <input class="form-control" placeholder="yyyy-mm-dd" name="dateUntil" [(ngModel)]="queryParameters.dateUntil"
                            ngbDatepicker #d2="ngbDatepicker" (ngModelChange)=onSubmit()>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button">
                            <i class="fa fa-calendar"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="level">Min log level</label>
                <select class="form-control" name="level" [(ngModel)]="queryParameters.level" (change)="onSubmit()">
                    <option value="0">0-Trace</option>
                    <option value="1">1-Debug</option>
                    <option value="2">2-Info</option>
                    <option value="3">3-Warning</option>
                    <option value="4">4-Error</option>
                    <option value="5">5-Critical</option>
                </select>
            </div>            
            <div class="form-group">
                <label for="request">Request</label>
                <div class="input-group">
                    <input type="text" class="form-control" name="request" [(ngModel)]="queryParameters.request" (change)=onSubmit()>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="queryParameters.request = ''; onSubmit()" type="button">
                            <i class="fa fa-remove"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="user">User</label>
                <div class="input-group">
                    <input type="text" class="form-control" name="user" [(ngModel)]="queryParameters.user" (change)=onSubmit()>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="queryParameters.user = ''; onSubmit()" type="button">
                            <i class="fa fa-remove"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="entity">Entity</label>
                <div class="input-group">
                    <input type="text" class="form-control" name="entity" [(ngModel)]="queryParameters.entity" (change)=onSubmit()>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="queryParameters.entity = ''; onSubmit()" type="button">
                            <i class="fa fa-remove"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="source">Source</label>
                <div class="input-group">
                    <input type="text" class="form-control" name="source" [(ngModel)]="queryParameters.source" (change)=onSubmit()>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="queryParameters.source = ''; onSubmit()" type="button">
                            <i class="fa fa-remove"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="message">Log message</label>
                <div class="input-group">
                    <input type="text" class="form-control" name="message" [(ngModel)]="queryParameters.message" (change)=onSubmit()>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="queryParameters.message = ''; onSubmit()" type="button">
                            <i class="fa fa-remove"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label for="exception">Error</label>
                <div class="input-group">
                    <input type="text" class="form-control" name="exception" [(ngModel)]="queryParameters.exception" (change)=onSubmit()>
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" (click)="queryParameters.exception = ''; onSubmit()" type="button">
                            <i class="fa fa-remove"></i>
                        </button>
                    </div>
                </div>
            </div>

            <!--
            <input type="submit" value="Search" class="btn btn-primary btn-block">
            -->
        </form>        
    </div>
</div>

