import { Component, OnInit, ViewChild, TemplateRef, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AgmGeocoder } from '@agm/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupplierInfoService } from '../../services/supplier-info.service'
import {Location} from '../../models/Location'
import { Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';

@Component({
  selector: 'app-supplier-register-data',
  templateUrl: './supplier-register-data.component.html',
  styleUrls: ['./supplier-register-data.component.scss']
})
export class SupplierRegisterDataComponent implements OnInit {

  dataForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required)
  })
  get name() { return this.dataForm.get('name');}
  get address() { return this.dataForm.get('address');}
  private location: Location = null
  mapsUrl: string = null
    
  constructor(
    private supplierInfoService: SupplierInfoService, 
    private geocoder: AgmGeocoder, 
    private router: Router,
    private modalService: NgbModal,
    private ngZone: NgZone
  ) {  }

  ngOnInit(): void {
  }


  public save(){   
    this.clearError()
     
    if(this.dataForm.invalid){
      console.log("invalid, not saving")  
      return
    }

    console.log("validating address")
    let request = { address: this.dataForm.value.address }
    this.geocoder.geocode(request).subscribe(
      (results) => this.ngZone.run(() => {
        console.log(results);
        if(results.length == 0) {
          this.showError("Address not found")
        } else {
          console.log("address found")   
          let result = results[0];
          this.location = {latitude: result.geometry.location.lat(), longitude: result.geometry.location.lng() };
          this.dataForm.patchValue({address: result.formatted_address});

          console.log("saving")    
          this.supplierInfoService.newSupplier({
            ...this.dataForm.value,
            location: this.location
          }).subscribe(
            (_) => this.router.navigateByUrl('/sup'),
            (err) => this.showError(err.message)
          )
        }
      }),
      (err) => this.ngZone.run(() => this.showError("Address not found"))
    )
  }

  errorMessage: string = null  
  private showError(message: string){
    this.errorMessage = message;
  }
  clearError(){
    this.errorMessage = null
  }

}
