import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'

import { SupplierInfoService } from '../../services/supplier-info.service'

@Component({
  selector: 'app-supplier-widget-stripe',
  templateUrl: './supplier-widget-stripe.component.html',
  styleUrls: ['./supplier-widget-stripe.component.scss']
})
export class SupplierWidgetStripeComponent implements OnInit {
  onboardingLink: string = null

  constructor(@Inject(DOCUMENT) private document: Document, public supplierInfoService: SupplierInfoService, ) { }

  ngOnInit(): void {    
  }

  startOnboarding(){
    this.clearError()
    this.supplierInfoService.getStripeOnboardingLink().subscribe(
      (link) => {
        console.log(`onboarding: rerouting to link: ${link.url}`)
        this.document.location.href = link.url;
      },
      err => this.showError(err.message)
    )
  }

  visitDashboard(){
    this.clearError()
    this.supplierInfoService.getStripeDashboardLink().subscribe(
      (link) => {
        console.log(`dashboard: rerouting to link: ${link.url}`)
        window.open(link.url);
      },
      err => this.showError(err.message)
    )
  }

  errorMessage: string = null
  private showError(message: string){
    this.errorMessage = message;
  } 
  clearError(){
    this.errorMessage = null
  }

}
