<div class="container">
    <div class="row">
        <ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="true" (closed)="clearError()">{{errorMessage}}</ngb-alert>
    </div>
    <div class="openinghours-container">
        <div class="openinghours-day" *ngFor="let day of openingHours | keyvalue">
            <p>{{dayNames.get(day.key) | translate}}</p>
            <div class="slot-list">
                <div *ngFor="let slot of day.value; let i = index">
                    <div class="slot">
                        <div class="slot-time">
                            <ngb-timepicker *ngIf="slot.edit" [(ngModel)]="slot.editStartTime" [minuteStep]="5" size="large"></ngb-timepicker>
                            <p *ngIf="!slot.edit">{{slot.startHour | number: '2.0-0'}}:{{slot.startMinute | number: '2.0-0'}}</p>
                        </div>
                        <p>-</p>
                        <div class="slot-time">
                            <ngb-timepicker *ngIf="slot.edit" [(ngModel)]="slot.editEndTime" [minuteStep]="5"  size="large"></ngb-timepicker>
                            <p *ngIf="!slot.edit">{{slot.endHour | number: '2.0-0'}}:{{slot.endMinute | number: '2.0-0'}}</p>
                        </div>
                        <div class="slot-buttons">
                            <button class="btn btn-outline-primary" (click)="deleteSlot(day.key, i)">
                                <i class="fa fa-trash fa-lg"></i>
                            </button> 
                            <button *ngIf="!slot.edit" class="btn btn-outline-primary" (click)="slot.edit = true">
                                <i class="fa fa-edit fa-lg"></i>
                            </button>
                            <button *ngIf="slot.edit" class="btn btn-outline-success" (click)="slot.edit = false; updateSlot(day.key, i)">
                                <i class="fa fa-check fa-lg"></i>
                            </button>                            
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <button class="btn btn-outline-primary" (click)="addSlot(day.key)">
                        <i class="fa fa-plus fa-lg"></i>
                    </button> 
                    <p class="not-open" *ngIf="day.value.length == 0">{{'openingHours.notOpen' | translate}}</p>           
                </div>  
            </div>    
        </div>               
    </div>
</div>

