import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Campaign } from 'src/app/models/Campaign';
import { SupplierAppointment } from 'src/app/models/SupplierAppointment';
import { AppointmentService } from 'src/app/services/appointment.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-supplier-campaign-detail',
  templateUrl: './supplier-campaign-detail.component.html',
  styleUrls: ['./supplier-campaign-detail.component.scss']
})
export class SupplierCampaignDetailComponent implements OnInit {
  
  public _campaign: Campaign
  get campaign(){
    return this._campaign
  }
  @Input()
  set campaign(val: Campaign){
    this._campaign = val
    this.updateCampaignStats()
  }
  
  @Output() errorMessage = new EventEmitter<string>();
  @Output() startEdit = new EventEmitter()
  @Output() enabledChanged = new EventEmitter<Campaign>()

  requestCount: number = 0
  appointments: SupplierAppointment[] = []
  totalSum: number = 0
  status: string = ''

  constructor(
    private campaignService: CampaignService,
    private appointmentService: AppointmentService, 
    private requestService: RequestService
  ) { }

  ngOnInit(): void {
  }

  toggleEnabled(){
    this.campaign.enabled = !this.campaign.enabled
    this.campaignService.updateCampaign(this.campaign).subscribe(
      result => this.enabledChanged.emit(result),
      err => this.showError(err.message)
    )
    this.campaign.status = this.campaignService.calculateStatus(this.campaign)
  }

  updateCampaignStats(){
    this.requestService.getRequestCountForCampaign(this._campaign.id).subscribe(
      result => this.requestCount = result.count,
      err => this.showError(err.message)
    )
    this.appointmentService.getAppointmentsForCampaign(this._campaign.id).subscribe(
      result => {
        this.appointments = result
        this.calculateTotalSum()
      },
      err => this.showError(err.message)
    )    
  }

  private calculateTotalSum(){
    this.totalSum = this.appointments.map(a => a.price).reduce((n,a) => n+a, 0)
  }

  private showError(message: string){
    this.errorMessage.emit(message);
  } 
}
