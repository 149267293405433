<img src="{{(supplierInfoService.supplierLogoUrl$ | async)}}"
             class="rounded-circle"
             style="width: 200px; height: 200px; object-fit: cover;" />

<input type="file" #file 
    [imageToDataUrl]="imageOptions" 
    (imageSelected)="selected($event)"
    placeholder="Choose file" style="display:none;">
<button type="button" class="btn btn-primary m-2" (click)="file.click()">Change logo image</button>

