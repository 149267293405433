<div class="edit-container">
    <form [formGroup]="dataForm" (ngSubmit)="save()">
        <div class="form-group">
            <label for="name-input">{{'campaigns.name' | translate}}</label>
            <input class="form-control" type="text" 
                name="name-input" formControlName="name" 
                [class.is-invalid]="name.invalid && (name.dirty || name.touched)" />
            <div *ngIf="name.invalid && (name.dirty || name.touched)">
                <small *ngIf="name.errors.required" class="form-control-feedback text-danger">Name is required</small>
            </div>
        </div>
        <div class="form-group">
            <label for="date-input">{{'campaigns.date' | translate}}</label>
            <div class="input-group">
                <input class="form-control" placeholder="dd mm yyyy" name="date-input" 
                        formControlName="date" ngbDatepicker #d1="ngbDatepicker" >
                <div class="input-group-append">
                    <button class="btn btn-outline-primary calendar" (click)="d1.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="times">
            <div class="form-group">
                <label for="startInput">{{'campaigns.start' | translate}}</label>
                <ngb-timepicker formControlName="startTime" [minuteStep]="15" #startInput  ></ngb-timepicker>
            </div>
            <div class="form-group">
                <label for="endInput">{{'campaigns.end' | translate}}</label>
                <ngb-timepicker formControlName="endTime" [minuteStep]="15" #endInput ></ngb-timepicker>
            </div>
        </div>        
        <div class="text-right">            
            <button class="btn btn-primary m-1" type="submit">{{'save' | translate}}</button>
            <button class="btn btn-primary m-1" type="button" (click)="cancel()">{{'cancel' | translate}}</button>
        </div>
    </form>
</div>
