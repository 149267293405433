<div class="container">
    <div class="row">
        <ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="false" >{{errorMessage}}</ngb-alert>    
    </div>
    <div class="row">
        <a type="button" class="btn btn-primary"[routerLink]="['/sup','newcampaign']" style="margin-bottom: 1rem;">{{'campaigns.newCampaign' | translate}}</a>
    </div>
    <div class="row campaigns-container">
        <div class="campaign" *ngFor="let campaign of campaigns" [ngClass]="{'active': campaign.c.status==='active','paused':campaign.c.status==='disabled'}">
            <div class="campaign-data-line">            
                <p class="date">{{campaign.c.start|date : 'dd. MMM. yy'}}</p>
                <p class="time">{{campaign.c.start|date : 'HH:mm' }} - {{campaign.c.end|date : 'HH:mm' }}</p>
                <p class="name">{{campaign.c.name}}</p>
                <div class="buttons">
                    <a type="button" *ngIf="!campaign.details" class="btn btn-outline-primary" (click)="campaign.details = !campaign.details"  >
                        <i class="fa fa-chevron-down"></i>
                    </a>
                    <a type="button" *ngIf="campaign.details" class="btn btn-outline-primary" (click)="campaign.details = !campaign.details"  >
                        <i class="fa fa-chevron-up"></i>
                    </a>
                    <a type="button" *ngIf="campaign.details" class="btn btn-outline-primary" (click)="campaign.edit=!campaign.edit">
                        <i class="fa fa-pencil"></i>
                    </a>
                </div>                
            </div>
            <div class="campaign-details-container" *ngIf="campaign.details && !campaign.edit">
                <app-supplier-campaign-detail [campaign]="campaign.c" style="width: 60%;"></app-supplier-campaign-detail>
            </div>
            <div class="campaign-details-container" *ngIf="campaign.details && campaign.edit">
                <app-supplier-campaign-edit [campaign]="campaign.c" style="width: 60%;" (canceled)="campaign.edit=false" (edited)="campaignEdited($event)" ></app-supplier-campaign-edit>
            </div>
        </div>
    </div>
    

    
</div>
