<div class="container-fluid">
    <div class="row" style="height: 100vh;">
      <div class="col-md-3">
        <app-admin-log-filter [parameterSetter]="parameterSetter" (newQuery)="onNewQuery($event)"></app-admin-log-filter>
      </div>
      <div class="col-md-9" style="height: 100%; overflow-y: scroll;">
        <ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="true" (closed)="clearError()">{{errorMessage}}</ngb-alert>
        <table class="table">
            <thead>
                <th scope="col">Date</th>
                <th scope="col">Request</th>
                <th scope="col">Level</th>
                <th scope="col">User</th>
                <th scope="col">Entity</th>
                <th scope="col">Source</th>
                <th scope="col">Message</th>
            </thead>
            <tbody>
                <tr *ngFor="let entry of logEntries">
                   <td>{{ entry.timestamp | date: 'HH:mm:ss yyyy.MM.dd' }}</td>
                   <td><a [routerLink]="" (click)="setQueryParameter('request',entry.request)">{{ entry.request }}</a></td>
                   <td>{{ entry.level }}</td>
                   <td><a [routerLink]="" (click)="setQueryParameter('user',entry.user)">{{ entry.user }}</a></td>
                   <td><a [routerLink]="" (click)="setQueryParameter('entity',entry.entity)" >{{ entry.entity }}</a></td>
                   <td><a [routerLink]="" (click)="setQueryParameter('source',entry.source)" >{{ entry.source }}</a></td>
                   <td>{{ entry.message }}</td>
                </tr>
            </tbody>
        </table>
      </div>      
    </div>
  </div>
 


