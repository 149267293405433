import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators'

import { SupplierAppointment } from '../models/SupplierAppointment'
import { ServiceCollectionService} from './service-collection.service'

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  private baseUrl = '/api/supplier/appointment'
  private statusCaptions:Map<number,string> = new Map([
    [0, "Open"],
    [1, "Closed"],
    [2, "Canceled by customer"],
    [3, "Customer no-show"],
    [4, "Canceled by supplier"],
    [5, "Supplier no-show"],
    [9, "Error"],
  ])

  constructor(private http: HttpClient, private serviceCollectionService: ServiceCollectionService) { }

  getAppointmentCountForPeriod(days: number): Observable<{count: number}>{
    let params = new HttpParams().set('days', days.toString())
    return this.http.get<{count: number}>(`${this.baseUrl}/count`, {params: params})
  }

  getAppointmentCountForCampaign(campaignId: string): Observable<{count: number}>{
    let params = new HttpParams().set('campaign', campaignId)
    return this.http.get<{count: number}>(`${this.baseUrl}/count`, {params: params})
  }

  getAppointmentsForCampaign(campaignId: string): Observable<SupplierAppointment[]>{
    let params = new HttpParams().set('campaign', campaignId)
    return this.getWithParams(params)
  }

  getAppointments(limit: number, offset: number): Observable<SupplierAppointment[]>{
    let params = new HttpParams()
      .set('limit', limit.toString())
      .set('offset', offset.toString())

    return this.getWithParams(params)
  }

  private getWithParams(params: HttpParams){
    let serviceMap = this.serviceCollectionService.serviceMap; 
    return this.http.get<SupplierAppointment[]>(this.baseUrl, {params: params})
      .pipe(map(appts => 
        appts.map(
          (appt) => { return {...appt, serviceCaption: serviceMap.get(appt.service), statusCaption: this.statusCaptions.get(appt.status)}}
        )
      ))
  }
}
