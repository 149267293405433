<div class="container images-container">
    <div class="image-config">
        <input type="file" #logoFile 
            [imageToDataUrl]="logoOptions" 
            (imageSelected)="onLogoSelected($event)"
            style="display:none;">
        <button type="button" class="btn btn-primary" (click)="logoFile.click()">{{'images.selectLogo' | translate}}</button>
        <img src="{{(supplierInfoService.supplierLogoUrl$ | async)}}"
        class="rounded-circle"
        style="width: 200px; height: 200px; object-fit: cover;" />
    </div>

    <div class="image-config">
        <input type="file" #profilePictureFile 
            [imageToDataUrl]="profileImageOptions" 
            (imageSelected)="onProfileImageSelected($event)"
            style="display:none;">
        <button type="button" class="btn btn-primary" (click)="profilePictureFile.click()">{{'images.selectProfileImage' | translate}}</button>
        <img src="{{(supplierInfoService.supplierProfileImageUrl$ | async)}}" />
    </div>
</div>



