import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '@auth0/auth0-angular'
import { HomeComponent } from './pages/home/home.component'
import { SupplierPageComponent } from './pages/supplier-page/supplier-page.component'
import { SupplierDashboardComponent } from './components/supplier-dashboard/supplier-dashboard.component'
import { SupplierImagesComponent } from './components/supplier-images/supplier-images.component';
import { SupplierProfileComponent } from './components/supplier-profile/supplier-profile.component'
import { SupplierServicesComponent } from './components/supplier-services/supplier-services.component'
import { SupplierRegistrationPageComponent } from './pages/supplier-registration-page/supplier-registration-page.component'
import { SupplierHistoryComponent } from './components/supplier-history/supplier-history.component';
import { SupplierOpeningHoursComponent } from './components/supplier-opening-hours/supplier-opening-hours.component'
import { AdminPageComponent} from './pages/admin-page/admin-page.component'
import { AdminLogComponent } from './components/admin-log/admin-log.component'
import { SupplierCampaignsComponent } from './components/supplier-campaigns/supplier-campaigns.component'
import { SupplierCampaignNewComponent } from './components/supplier-campaign-new/supplier-campaign-new.component'
import { ImpressumComponent } from './pages/impressum/impressum.component';
import { compileComponentFromMetadata } from '@angular/compiler';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'lp'},
  {path: 'lp/:role', component: HomeComponent},
  {
    path: 'sup',
    component: SupplierPageComponent, 
    canActivate: [AuthGuard], 
    children: [
      {path:'', pathMatch: 'full', redirectTo: 'dashboard' },
      {path:'dashboard', component: SupplierDashboardComponent},
      {path:'images', component: SupplierImagesComponent},
      {path:'profile', component: SupplierProfileComponent},
      {path:'services', component: SupplierServicesComponent },
      {path:'openinghours', component: SupplierOpeningHoursComponent },
      {path:'history', component: SupplierHistoryComponent },
      {path:'campaigns', component: SupplierCampaignsComponent },
      {path:'newcampaign', component: SupplierCampaignNewComponent }
    ]
  },
  {path: 'register', component: SupplierRegistrationPageComponent, canActivate: [AuthGuard]},
  {
    path: 'admin', 
    component: AdminPageComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'log'},
      {path: 'log', component: AdminLogComponent}
    ]
  },
  {path: 'impressum', component: ImpressumComponent},   
  {path:'**', component: HomeComponent}
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
