import { Component, OnInit, Input } from '@angular/core';
import { SupplierAppointment } from 'src/app/models/SupplierAppointment';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.scss']
})
export class AppointmentListComponent implements OnInit {
  @Input() appointments: SupplierAppointment[] = []

  constructor() { }

  ngOnInit(): void {
  }

}
