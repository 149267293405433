import { Component, OnInit } from '@angular/core';

import {SupplierInfoService} from '../../services/supplier-info.service'
import { SupplierOpeningHourSlot } from '../../models/SupplierOpeningHourSlot'
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { startWith } from 'rxjs/operators';
import { R3FactoryDelegateType } from '@angular/compiler/src/render3/r3_factory';

@Component({
  selector: 'app-supplier-opening-hours',
  templateUrl: './supplier-opening-hours.component.html',
  styleUrls: ['./supplier-opening-hours.component.scss']
})
export class SupplierOpeningHoursComponent implements OnInit {
  
  openingHours: Map<number, {startHour: number, startMinute: number, endHour: number, endMinute: number, edit: boolean, editStartTime: NgbTimeStruct, editEndTime: NgbTimeStruct}[]> 
    = new Map([[1,[]],[2,[]],[3,[]],[4,[]],[5,[]],[6,[]],[7,[]]])
  dayNames: Map<number,string> = new Map([[1,"monday"],[2,"tuesday"],[3,"wednesday"],[4,"thursday"],[5,"friday"],[6,"saturday"],[7,"sunday"]])

  constructor(private supplierInfoService: SupplierInfoService) { }

  ngOnInit(): void {
    this.supplierInfoService.openingHours$.subscribe((serviceOpeningHours) => {
      this.openingHours = new Map([[1,[]],[2,[]],[3,[]],[4,[]],[5,[]],[6,[]],[7,[]]])
      serviceOpeningHours.forEach(element => {
        this.openingHours.get(element.weekDay).push({...element, edit: false, 
          editStartTime: {hour: element.startHour, minute: element.startMinute, second: 0},
        editEndTime: {hour: element.endHour, minute: element.endMinute, second: 0}})
      });
    })
  }

  deleteSlot(day: number, index: number){
    this.openingHours.get(day).splice(index, 1)
    this.sendUpdate()
  }

  updateSlot(day: number, index: number){
    console.log(index);
    console.log(this.openingHours.get(day)[index].editStartTime)

    let slot = this.openingHours.get(day)[index]
    slot.startHour = slot.editStartTime.hour
    slot.startMinute = slot.editStartTime.minute
    slot.endHour = slot.editEndTime.hour
    slot.endMinute = slot.editEndTime.minute  
    
    this.sendUpdate()
  }

  addSlot(day: number){
    let ref = this.openingHours.get(day).length > 0
      ? this.openingHours.get(day)[this.openingHours.get(day).length - 1]
      : {startHour: 0, startMinute: 0, endHour: 0, endMinute: 0}

    this.openingHours.get(day).push({
      startHour: ref.endHour, startMinute: ref.endMinute, endHour: ref.endHour, endMinute: ref.endMinute, 
      edit: true, 
      editStartTime: {hour: ref.endHour, minute: ref.endMinute, second: 0},
      editEndTime: {hour: ref.endHour, minute: ref.endMinute, second: 0}})
  }

  private sendUpdate(){
    let slots: SupplierOpeningHourSlot[] = []    
    this.openingHours.forEach((value, key, map) => {
      value.forEach((slot) => {
        slots.push({weekDay: key, startHour: slot.startHour, startMinute: slot.startMinute, endHour: slot.endHour, endMinute: slot.endMinute})
      })
    })
    
    this.supplierInfoService.updateOpeningHours(slots).subscribe(
      (_) => {},
      (err) => this.showError(err.message)
    )
  }

  errorMessage: string = null
  private showError(message: string){
    this.errorMessage = message;
  } 
  clearError(){
    this.errorMessage = null
  }
}
