import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SupplierInfoService } from 'src/app/services/supplier-info.service';
import TextsCustomerDe from '../../../assets/data/home-texts-customer-de.json'
import TextsCustomerEn from '../../../assets/data/home-texts-customer-en.json'
import TextsSupplierDe from '../../../assets/data/home-texts-supplier-de.json'
import TextsSupplierEn from '../../../assets/data/home-texts-supplier-en.json'

interface faq {
  id: number
  question: string
  answer: string
  expanded: boolean
}
interface titledParagraph {
  title: string,
  paragraph: string
} 
interface homeTexts {
  supplierLink: string,
  customerLink: string,
  headerParagraph: string
  headerButton: string
  bulletpoint1: titledParagraph
  bulletpoint2: titledParagraph
  bulletpoint3: titledParagraph
  bulletpoint4: titledParagraph
  carousel: titledParagraph[]
  block: titledParagraph,
  faqTitle: string,
  faqs: faq[]
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject()

  public texts: homeTexts
  
  public carouselCount: number
  public carouselPosition: number = 0
  public carouselTitle: string
  public carouselParagraph: string

  public role: string = "cust"
  public language = window.navigator.language.substring(0,2)
  private availableLanguages = ["de","en"] 
  private defaultLanguage = "en"

  constructor(
    private route: ActivatedRoute, 
    private supplierInfoService: SupplierInfoService,
    private router: Router) { }

  ngOnInit(): void {
    if(!this.availableLanguages.includes(this.language))
      this.language = this.defaultLanguage

    this.route.paramMap.subscribe(params => {
      this.role = params.get('role') ?? "cust"

      if(this.role == "cust"){
        if(this.language == "de"){
          this.texts = TextsCustomerDe
        } else {
          this.texts = TextsCustomerEn
        }
      } else {
        if(this.language == "de"){
          this.texts = TextsSupplierDe
        } else {
          this.texts = TextsSupplierEn
        }
      }
      this.carouselCount = this.texts.carousel.length
      this.updateCarouselTexts()
    })    

    this.supplierInfoService.supplierId$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (supplierId: string | null) => {
        if(supplierId) {
          this.router.navigate(['sup','dashboard'])
        }
      }})
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public carouselImageId(phonePosition: number): string {
    return `screen-${this.role}-${this.language}-${(this.carouselCount + this.carouselPosition + phonePosition)%this.carouselCount}` 
  }

  moveCarouselLeft(){
    this.carouselPosition =(this.carouselPosition + 1)%this.carouselCount
    this.updateCarouselTexts()
  }

  moveCarouselRight(){
    this.carouselPosition =(this.carouselCount + this.carouselPosition - 1)%this.carouselCount
    this.updateCarouselTexts()
  }

  private updateCarouselTexts(){
    this.carouselTitle = this.texts.carousel[this.carouselPosition].title    
    this.carouselParagraph = this.texts.carousel[this.carouselPosition].paragraph    
  }



}
