import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpUrlEncodingCodec } from '@angular/common/http'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { AuthService } from '@auth0/auth0-angular'

import { ServiceCollectionService } from './service-collection.service'
import { SupplierProfile } from '../models/SupplierProfile'
import { SupplierServiceEntry } from '../models/SupplierServiceEntry';
import { SupplierOpeningHourSlot } from '../models/SupplierOpeningHourSlot'
import { ApiSupplierInfo } from '../models/api/ApiSupplierInfo'
import {Location} from '../models/Location'
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupplierInfoService {  
  private baseUrl: string = '/api/SupplierInfo'
  private currentUser: string = null

  public supplierId: string = null
  public supplierId$: Subject<string> = new Subject<string>()

  private supplierProfile: SupplierProfile = null
  private _supplierProfile$: BehaviorSubject<SupplierProfile> = new BehaviorSubject<SupplierProfile>(null);
  public supplierProfile$: Observable<SupplierProfile> = this._supplierProfile$.asObservable();

  private supplierLogoUrl: string = null
  private _supplierLogoUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  public supplierLogoUrl$: Observable<string> = this._supplierLogoUrl$.asObservable();

  private supplierProfileImageUrl: string = null
  private _supplierProfileImageUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  public supplierProfileImageUrl$: Observable<string> = this._supplierProfileImageUrl$.asObservable();

  private serviceEntries: SupplierServiceEntry[];
  private _serviceEntries$: BehaviorSubject<SupplierServiceEntry[]> = new BehaviorSubject([]);
  public serviceEntries$: Observable<SupplierServiceEntry[]> = this._serviceEntries$.asObservable();

  private openingHours: SupplierOpeningHourSlot[];
  private _openingHours$: BehaviorSubject<SupplierOpeningHourSlot[]> = new BehaviorSubject([]);
  public openingHours$: Observable<SupplierOpeningHourSlot[]> = this._openingHours$.asObservable();

  private paymentAccountActive: boolean = null
  private _paymentAccountActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public paymentAccountActive$: Observable<boolean> = this._paymentAccountActive$.asObservable();
  
  constructor(private http: HttpClient, private auth: AuthService, private serviceCollectionService: ServiceCollectionService) {
    // when a user logged in, check if there is supplier data for that user      
    auth.user$.subscribe((user) => {
      console.log(user)
      if(user == null){ // no user logged in
        this.currentUser = null
        this._supplierProfile$.next(null)
        this._serviceEntries$.next(null)
        this.supplierId = null
        this.supplierId$.next(this.supplierId)
      } else {
        this.currentUser = user?.email
        this.http.get<ApiSupplierInfo>(`${this.baseUrl}/fromUser`).subscribe((result) => {
          if(result == null || result.id == ""){ 
            // user is not assigned to a supplier
            this._supplierProfile$.next(null);
            this._serviceEntries$.next(null);
            this._openingHours$.next(null);
            this.supplierId = null;
            this.supplierId$.next(this.supplierId)
          } else {
            this.processApiData(result);
            this.refreshPaymentAccountStatus();
          }              
        })
      }        
      console.log(this.currentUser)
    })
   }

   newSupplier(supplierData: {name: string, address: string, location: Location}): Observable<SupplierProfile>{
    if(this.supplierId != null){
      throw 'You are already linked to a supplier.'      
    }
    let newSupplierInfo: ApiSupplierInfo = {
      ...supplierData,
      description: "",
      userName: this.currentUser,
      services: [],
      openingHours: []
    }
    console.log(newSupplierInfo)
    return this.http.post<ApiSupplierInfo>(this.baseUrl, newSupplierInfo).pipe(map(
      (result) => {
        this.processApiData(result)
        return this.supplierProfile
      }))
  }

  updateProfile(newProfile: SupplierProfile): Observable<SupplierProfile>{
    this.supplierProfile = {...this.supplierProfile, ...newProfile};
    return this.update();
  }

   addServices(newServices: {name: string, price: number}[]): Observable<SupplierProfile>{
      let serviceMap = this.serviceCollectionService.serviceMap; 
      newServices.forEach((newService) => {
        if(!this.serviceEntries.some((se) => se.serviceName == newService.name)){
          this.serviceEntries.push({
            serviceName: newService.name, 
            serviceCaption: serviceMap.get(newService.name), 
            defaultPrice: newService.price
          })
        }
      })
      
      return this.update()
   }

  deleteService(serviceFullName: string): Observable<SupplierProfile>{
    this.serviceEntries.splice(this.serviceEntries.findIndex(se => se.serviceName == serviceFullName),1)
    return this.update()
  }

  updateServicePrice(serviceFullName: string, newPrice: number): Observable<SupplierProfile> {
    console.log()
    this.serviceEntries.find(se => se.serviceName == serviceFullName).defaultPrice = newPrice;
    return this.update();
  }

  updateOpeningHours(slots: SupplierOpeningHourSlot[]): Observable<SupplierProfile>{
    this.openingHours = slots
    this._openingHours$.next(this.openingHours)
    return this.update()
  }

  updateLogo(file: Blob){
    const formdata = new FormData()
    formdata.append("file", file)

    this.http.put(`${this.baseUrl}/${this.supplierId}/logo`, formdata, {reportProgress: false}).subscribe((result) => {
      this.publishLogoUrl()
    })
  }

  updateProfileImage(file: Blob){
    const formdata = new FormData()
    formdata.append("file", file)

    this.http.put(`${this.baseUrl}/${this.supplierId}/profileimage`, formdata, {reportProgress: false}).subscribe((result) => {
      this.publishProfileImageUrl()
    })
  }
  

  getStripeOnboardingLink(): Observable<{url: string}>{
    let params = new HttpParams()
      .set('returnUrl', 'http://localhost:4200/sup/dashboard')
      .set('refreshUrl','http://localhost:4200/sup/dashboard')
    
      return this.http.get<{url: string}>(`${this.baseUrl}/${this.supplierId}/onboardlink`, {params: params} );
  }

  getStripeDashboardLink(): Observable<{url: string}>{
    return this.http.get<{url: string}>(`${this.baseUrl}/${this.supplierId}/dashboardlink`);
  }
  
  private publishLogoUrl() {
    // add a timestamp so that the image isalways refreshed, even if the url remains the same 
    this.supplierLogoUrl = `${this.baseUrl}/${this.supplierId}/logo?${new Date().valueOf()}`
    console.log(this.supplierLogoUrl)
    this._supplierLogoUrl$.next(this.supplierLogoUrl)
  }

  private publishProfileImageUrl() {
    // add a timestamp so that the image isalways refreshed, even if the url remains the same 
    this.supplierProfileImageUrl = `${this.baseUrl}/${this.supplierId}/profileimage?${new Date().valueOf()}`
    console.log(this.supplierProfileImageUrl)
    this._supplierProfileImageUrl$.next(this.supplierProfileImageUrl)
  }

  private processApiData(apiData: ApiSupplierInfo){
    this.supplierId = apiData.id; 
    this.supplierId$.next(this.supplierId); 
    console.log(`supplierID: ${this.supplierId}`)
    
    this.publishLogoUrl()  
    this.publishProfileImageUrl()  

    this.supplierProfile = {
      id: apiData.id,
      name: apiData.name,
      location: apiData.location,
      userName: apiData.userName,
      address: apiData.address,
      website: apiData.website,
      description: apiData.description
    }
    this._supplierProfile$.next(this.supplierProfile);
    // get services and prices
    let serviceMap = this.serviceCollectionService.serviceMap;            
    let newServiceList: SupplierServiceEntry[] = [];
    apiData.services.forEach(element => {
      newServiceList.push({
        serviceName: element.service, 
        serviceCaption: serviceMap.get(element.service), 
        defaultPrice: element.defaultPrice })
    });
    this.serviceEntries = newServiceList
    this._serviceEntries$.next(newServiceList);
    // get opening hours
    let newOpeningHours: SupplierOpeningHourSlot[] = [];
    apiData.openingHours.forEach(element => {
      element.weekDays.forEach(day => {
        newOpeningHours.push({
          weekDay: day,
          startHour: element.startHour,
          startMinute: element.startMinute,
          endHour: element.endHour,
          endMinute: element.endMinute
        })
      })
    })
    this.openingHours = newOpeningHours;
    this._openingHours$.next(newOpeningHours);    
  }

  private refreshPaymentAccountStatus(){
    this.http.get<{isActive: boolean}>(`${this.baseUrl}/${this.supplierId}/accountstatus`).subscribe((status) => {
      this.paymentAccountActive = status.isActive;
      this._paymentAccountActive$.next(this.paymentAccountActive);
    })
  }

  private update(): Observable<SupplierProfile>{
    console.log("updating");
    let apiInfo = {
      ...this.supplierProfile,
      services: this.serviceEntries.map(se => {return {service: se.serviceName, defaultPrice: se.defaultPrice}}),
      openingHours: this.openingHours.map(oh => {return {
          weekDays: [oh.weekDay], 
          startHour: oh.startHour, 
          startMinute: oh.startMinute, 
          endHour: oh.endHour, 
          endMinute: oh.endMinute}
        })
    }

    return this.http.put<ApiSupplierInfo>(`${this.baseUrl}/${this.supplierId}`, apiInfo)
            .pipe(map((result) => {
              this.processApiData(result)
              return this.supplierProfile
            }))
  }

}
