import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { ServiceCategory } from '../models/ServiceCategory';
import { Service } from '../models/Service';
import { ServiceGroup } from '../models/ServiceGroup';
import { group } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class ServiceCollectionService {
  private baseUrl = '/api/servicecollection'
  private language = window.navigator.language

  public serviceCategories: ServiceCategory[] = [];
  public serviceGroups: ServiceGroup[] = [];
  public serviceMap: Map<string,string> = new Map();

  constructor(private http: HttpClient) {
    let params = new HttpParams().set('language', this.language)
    http.get<ServiceCategory[]>(this.baseUrl, {params: params}).subscribe(
      (categories) => {
        this.serviceCategories = categories; 
        this.serviceCategories.forEach((category) => {
          category.groups.forEach((group) => {
            group.fullCaption = `${category.caption}${(group.caption.length > 0) ? " - " : ""}${group.caption}`
            this.serviceGroups.push(group)
          })
        })  
        this.serviceGroups.sort((g1,g2) => g1.fullCaption.localeCompare(g2.fullCaption))

        let map = new Map<string,string>();
        this.serviceCategories.forEach((category) => {
          category.groups.forEach((group) => {
            group.services.forEach((service) => map.set(`${service.name}`, service.caption))
          })
        })
        this.serviceMap = map;        
      })
  }

  public GetServicesForGroup(groupName: string): Service[]{
    return this.serviceGroups.find(g => g.name == groupName)?.services
  }
}
