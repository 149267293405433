import { Component, OnInit } from '@angular/core';

import { AppointmentService } from '../../services/appointment.service'
import { SupplierAppointment } from '../../models/SupplierAppointment'

@Component({
  selector: 'app-supplier-history',
  templateUrl: './supplier-history.component.html',
  styleUrls: ['./supplier-history.component.scss']
})
export class SupplierHistoryComponent implements OnInit {
  private pageSize: number = 5
  private currentOffset: number = 0

  appointments: SupplierAppointment[] = []
  showLoadMore: boolean = true
  constructor(private appointmentService: AppointmentService) { }

  ngOnInit(): void {
    this.loadNextPage()
  }
  
  loadNextPage(){
    this.clearError()
    this.appointmentService.getAppointments(this.pageSize, this.currentOffset).subscribe(
      (newAppointments) => {
        this.showLoadMore = newAppointments.length == this.pageSize
        this.appointments.push(...newAppointments)},
      (err) => this.showError(err.message)
    );
    this.currentOffset += this.pageSize
    console.log(this.appointments)
  }

  errorMessage: string = null
  private showError(message: string){
    this.errorMessage = message;
  } 
  clearError(){
    this.errorMessage = null
  }


}
