import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  private baseUrl = '/api/supplier/request'

  constructor(private http: HttpClient) { }

  getRequestCountForCampaign(campaignId: string): Observable<{count: number}>{
    let params = new HttpParams().set('campaign', campaignId)
    return this.http.get<{count: number}>(`${this.baseUrl}/count`, {params: params})
  }
}
