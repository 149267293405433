import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Campaign } from 'src/app/models/Campaign';
import { CampaignService } from 'src/app/services/campaign.service';

@Component({
  selector: 'app-supplier-campaign-edit',
  templateUrl: './supplier-campaign-edit.component.html',
  styleUrls: ['./supplier-campaign-edit.component.scss']
})
export class SupplierCampaignEditComponent implements OnInit {
  @Output() edited = new EventEmitter<Campaign>()
  @Output() canceled = new EventEmitter<void>()
  @Output() error = new EventEmitter<string>()

  private _campaign: Campaign = null  
  get campaign(): Campaign{
    return this._campaign
  }
  @Input()
  set campaign(val: Campaign){
    this._campaign = val
    this.resetForm()
  }

  dataForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    date: new FormControl({day: new Date().getDate(), month: new Date().getMonth()+1, year: new Date().getFullYear()}),
    startTime: new FormControl({hour: new Date().getHours()+1, minute: 0, second: 0 }),
    endTime: new FormControl({hour: new Date().getHours() + 2, minute: 0, second: 0 })
  })
  get name() {return this.dataForm.get('name');}

  constructor(private campaignService: CampaignService) { }

  ngOnInit(): void {
  }

  save(){
    if(this.dataForm.invalid){
      console.log("invalid, not saving")  
      return
    }

    let value = this.dataForm.value;
    let d = value.date;
    let st = value.startTime
    let et = value.endTime

    let campaign: Campaign = {
      id: this._campaign.id,
      supplierId: this._campaign.supplierId,
      name: value.name,
      start: new Date(d.year, d.month - 1, d.day, st.hour, st.minute, st.second),
      end: new Date(d.year, d.month - 1, d.day, et.hour, et.minute, et.second),
      enabled: this._campaign.enabled
    }

    this.campaignService.updateCampaign(campaign).subscribe(
      result => this.edited.emit(result),
      err => this.showError(err.message)
    )
  }

  cancel(){
    this.resetForm()    
    this.canceled.emit()
  }

  private resetForm(){
    let startDate = new Date(this._campaign.start)
    let endDate = new Date(this._campaign.end)
    this.dataForm.setValue({
      name: this._campaign.name, 
      date: {day: startDate.getDate(), month: startDate.getMonth()+1, year: startDate.getFullYear()},
      startTime: {hour: startDate.getHours(), minute: startDate.getMinutes(), second: 0},
      endTime: {hour: endDate.getHours(), minute: endDate.getMinutes(), second: 0},})
  }

  private showError(message: string){
    this.error.emit(message);
  }

}
