import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { AuthHttpInterceptor } from '@auth0/auth0-angular'
import { AuthModule } from '@auth0/auth0-angular'
import { AgmCoreModule } from '@agm/core'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageToDataUrlModule } from 'ngx-image2dataurl';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment as env } from '../environments/environment';

import { AppComponent } from './app.component';
import { AuthNavComponent } from './components/auth-nav/auth-nav.component';
import { HomeComponent } from './pages/home/home.component';
import { SupplierPageComponent } from './pages/supplier-page/supplier-page.component';
import { AppRoutingModule } from './app-routing.module';
import { SupplierDashboardComponent } from './components/supplier-dashboard/supplier-dashboard.component';
import { SupplierProfileComponent } from './components/supplier-profile/supplier-profile.component';
import { SupplierServicesComponent } from './components/supplier-services/supplier-services.component';
import { SupplierRegistrationPageComponent } from './pages/supplier-registration-page/supplier-registration-page.component';
import { SupplierRegisterDataComponent } from './components/supplier-register-data/supplier-register-data.component';
import { SupplierWidgetStripeComponent } from './components/supplier-widget-stripe/supplier-widget-stripe.component';
import { SupplierWidgetHistoryComponent } from './components/supplier-widget-history/supplier-widget-history.component';
import { SupplierHistoryComponent } from './components/supplier-history/supplier-history.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { AdminLogComponent } from './components/admin-log/admin-log.component';
import { AdminLogFilterComponent } from './components/admin-log-filter/admin-log-filter.component';
import { SupplierOpeningHoursComponent } from './components/supplier-opening-hours/supplier-opening-hours.component';
import { SupplierCampaignsComponent } from './components/supplier-campaigns/supplier-campaigns.component';
import { SupplierCampaignNewComponent } from './components/supplier-campaign-new/supplier-campaign-new.component';
import { SupplierCampaignDetailComponent } from './components/supplier-campaign-detail/supplier-campaign-detail.component';
import { SupplierCampaignEditComponent } from './components/supplier-campaign-edit/supplier-campaign-edit.component';
import { AppointmentListComponent } from './components/appointment-list/appointment-list.component';
import { SupplierLogoComponent } from './components/supplier-logo/supplier-logo.component';
import { SupplierImagesComponent } from './components/supplier-images/supplier-images.component';
import { ImpressumComponent } from './pages/impressum/impressum.component'

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    AuthNavComponent,
    HomeComponent,
    SupplierPageComponent,
    SupplierDashboardComponent,
    SupplierProfileComponent,
    SupplierServicesComponent,
    SupplierRegistrationPageComponent,
    SupplierRegisterDataComponent,
    SupplierWidgetStripeComponent,
    SupplierWidgetHistoryComponent,
    SupplierHistoryComponent,
    AdminPageComponent,
    AdminLogComponent,
    AdminLogFilterComponent,
    SupplierOpeningHoursComponent,
    SupplierCampaignsComponent,
    SupplierCampaignNewComponent,
    SupplierCampaignDetailComponent,
    SupplierCampaignEditComponent,
    AppointmentListComponent,
    SupplierLogoComponent,
    SupplierImagesComponent,
    ImpressumComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        allowedList: ['/api/*']
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: env.google.googleApiKey
    }),
    AppRoutingModule,
    NgbModule,
    ImageToDataUrlModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'de'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
