<div class="container">
    <div class="row">
        <div class="col-md">
            <app-supplier-widget-stripe></app-supplier-widget-stripe>
        </div>
        <div class="col-md">
            <app-supplier-widget-history></app-supplier-widget-history>
        </div>
    </div>
</div>
