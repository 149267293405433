<nav class="navbar navbar-expand-md navbar-light bg-light">
    <div class="container-fluid">
        <a href="/" class="navbar-brand"><img src="assets/img/apphoc-logo.png" height="45" width="150" /></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarContent">
            <div class="navbar-nav ml-auto">
                <a class="nav-link" routerLink="./log" >Logs</a>    
                <app-auth-nav></app-auth-nav>
            </div>
        </div>
    </div>  
    </nav>
    
    <router-outlet></router-outlet>
