import { Component, OnInit } from '@angular/core';
import { AppointmentService } from '../../services/appointment.service'

@Component({
  selector: 'app-supplier-widget-history',
  templateUrl: './supplier-widget-history.component.html',
  styleUrls: ['./supplier-widget-history.component.scss']
})
export class SupplierWidgetHistoryComponent implements OnInit {
  appointmentCount: number = 0
  days: number = 7

  constructor(private appointmentService: AppointmentService) { }

  ngOnInit(): void {
    this.appointmentService.getAppointmentCountForPeriod(this.days).subscribe(
      (result) => this.appointmentCount = result.count,
      (err) => this.showError(err.message)
    )
  }

  errorMessage: string = null
  private showError(message: string){
    this.errorMessage = message;
  } 
  clearError(){
    this.errorMessage = null
  }

}
