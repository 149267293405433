<div class="container">
    <div class="row">
        <ngb-alert *ngIf="errorMessage" type="danger" [dismissible]="true" (closed)="clearError()">{{errorMessage}}</ngb-alert>
    </div>

    <div class="row">
        <div class="entry placeholder-entry" *ngIf="!serviceEntries || serviceEntries.length == 0">
            <p>{{'services.pricelistPlaceholder' | translate}}</p>
        </div>
        <div class="price-list">
            <div class="entry price-list-entry" *ngFor="let entry of serviceEntries">
                <p class="name">{{entry.caption}}</p>
                <p *ngIf="!entry.edit" class="price">{{entry.price | currency:'EUR':'symbol'}}</p>
                <input *ngIf="entry.edit" type="number" min="0.00" step="0.01" class="price" [(ngModel)]="entry.price"/>
                <button *ngIf="!entry.edit" class="btn btn-outline-primary" (click)="entry.edit = true">
                    <i class="fa fa-pencil fa-lg"></i>
                </button>
                <button *ngIf="entry.edit" class="btn btn-outline-primary" (click)="entry.edit = false; updateServicePrice(entry.service)">
                    <i class="fa fa-check fa-lg"></i>
                </button>   
                <button class="btn btn-outline-primary" (click)="deleteService(entry.service)">
                    <i class="fa fa-trash fa-lg"></i>
                </button>              
            </div>
        </div>
    </div>
    <div class="row">
        <button *ngIf="!addInProgress" class="btn btn-primary add-button" type="button" (click)="addInProgress = true" >
            {{'services.startAdd' | translate}}
        </button>
    </div>
    <div class="row">
        <div *ngIf="addInProgress" class="add">
            <h4>{{'services.addTitle' | translate}}</h4>
            <select class="form-select" (change)="onGroupSelected($event.target.value)" >
                <option disabled selected hidden value>-- {{'services.categoryPlaceholder' | translate}} --</option>
                <option *ngFor="let group of serviceCollection.serviceGroups" [value]="group.name" >{{ group.fullCaption }}</option>
            </select>            
            <div class="service" *ngFor="let serviceOption of servicesToAdd">
                <p class="name">{{serviceOption.service.caption}}</p>
                <input type="number"  min="0.00" step="0.01" class="price" [(ngModel)]="serviceOption.price"/>
                <p>€</p>
            </div>
            <div class="button-bar">
                <button *ngIf="selectedGroup" class="btn btn-outline-light" (click)="save()">{{'services.add' | translate}}</button>
                <button class="btn btn-outline-light" (click)="addInProgress = false">{{'services.cancel' | translate}}</button>
            </div>   
        </div>          
    </div>    
</div>
