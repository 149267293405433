import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { Campaign } from '../../models/Campaign'
import { CampaignService } from '../../services/campaign.service'
import { SupplierInfoService} from '../../services/supplier-info.service'

@Component({
  selector: 'app-supplier-campaign-new',
  templateUrl: './supplier-campaign-new.component.html',
  styleUrls: ['./supplier-campaign-new.component.scss']
})
export class SupplierCampaignNewComponent implements OnInit {
  private now = new Date()

  @Output() success = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();

  dataForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    date: new FormControl({day: this.now.getDate(), month: this.now.getMonth()+1, year: this.now.getFullYear()}),
    startTime: new FormControl({hour: this.now.getHours()+1, minute: 0, second: 0 }),
    endTime: new FormControl({hour: this.now.getHours() + 2, minute: 0, second: 0 })
  })

  get name() {return this.dataForm.get('name');}

  selectServices = false
  services: {service: string, caption: string, selected: boolean}[] = []

  constructor(
    private campaignService: CampaignService,
    public supplierInfoService: SupplierInfoService,
    private router: Router  
  ) { }

  ngOnInit(): void {
    this.supplierInfoService.serviceEntries$.subscribe(entries => {
      // TODO: instead of replacing, only apply changes so that the selection is kept
      this.services = entries.map((e) => { return {service: e.serviceName, caption: e.serviceCaption, selected: false}})
    })    
  }

  save(){
    this.clearError()
    
    if(this.dataForm.invalid){
      console.log("invalid, not saving")  
      return
    }

    console.log("saving")    

    let value = this.dataForm.value;
    let d = value.date;
    let st = value.startTime
    let et = value.endTime

    let campaign: Campaign = {
      name: value.name,
      start: new Date(d.year, d.month - 1, d.day, st.hour, st.minute, st.second),
      end: new Date(d.year, d.month - 1, d.day, et.hour, et.minute, et.second),
      enabled: true
    }
    if(this.selectServices){
      campaign.services = this.services.filter(s => s.selected).map((s) => {return s.service})
    }

    this.campaignService.createCampaign(campaign).subscribe(
      (_) => this.router.navigate(['sup','campaigns']),
      (err) => this.showError(err.message))
  }

  cancel(){
    this.router.navigate(['sup','campaigns'])
  }

  errorMessage: string = null
  private showError(message: string){
    this.errorMessage = message;
  } 
  clearError(){
    this.errorMessage = null
  }

}

