import { Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { AgmGeocoder } from '@agm/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { SupplierInfoService } from '../../services/supplier-info.service'
import { SupplierProfile } from '../../models/SupplierProfile'
import { Location } from '../../models/Location'
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-supplier-profile',
  templateUrl: './supplier-profile.component.html',
  styleUrls: ['./supplier-profile.component.scss']
})
export class SupplierProfileComponent implements OnInit {
  profileForm = new FormGroup({
    name: new FormControl('', Validators.required),
    userName: new FormControl('', Validators.required),
    address: new FormControl(''),
    website: new FormControl(''),
    description: new FormControl('')
  })
  
  private location: Location
  mapsUrl: string = null

  get name() { return this.profileForm.get('name');}
  get userName() { return this.profileForm.get('userName');}

  private originalProfile: SupplierProfile = null

  
  constructor(
    public supplierInfoService: SupplierInfoService, 
    private geocoder: AgmGeocoder,
    private modalService: NgbModal,
    private ngZone: NgZone) { }

  ngOnInit(): void {
    this.supplierInfoService.supplierProfile$.subscribe((profile) => {
      this.originalProfile = profile;

      if(profile == null)
        return

      this.fillFormFromProfile(profile)
    })
  }

  private fillFormFromProfile(profile: SupplierProfile){
    if(profile == null)
      return

    this.profileForm.setValue({
      name: profile.name,
      userName: profile.userName,
      address: profile.address,
      website: profile.website,
      description: profile.description
    })
    this.location = profile.location
  }

  public save(){
    this.clearError()

    if(this.profileForm.invalid){
      console.log("invalid, not saving")  
      return
    }
    
    if(this.profileForm.value.address === this.originalProfile.address){
      this.doSave()
    } else {
      try {
        console.log("validating address")
        let request = { address: this.profileForm.value.address }
        this.geocoder.geocode(request).subscribe(
          (results) => this.ngZone.run(() => {
            console.log(results);
            if(results.length == 0) {
              throw "address not found"
            } else {
              console.log("address found")   
              let result = results[0];
              this.location = {latitude: result.geometry.location.lat(), longitude: result.geometry.location.lng() };
              this.profileForm.patchValue({address: result.formatted_address});
            }
            this.doSave()
          }),
          (err) => this.ngZone.run(() => this.showError("Address not found")))  
      } catch(message) {
        console.log("catched error")
        this.showError(message);
      }
    }
  }   

  private doSave(){
    console.log("saving") 
    this.supplierInfoService.updateProfile({
      ...this.profileForm.value,
      location: this.location
    }).subscribe(
      (res) => {},
      (err) => {
        console.log(err)
        this.showError(err.message)
      }
    )
  }

  errorMessage: string = null
  private showError(message: string){
    this.errorMessage = message;
  } 
  clearError(){
    this.errorMessage = null
  }

  public reset(){
    this.fillFormFromProfile(this.originalProfile)
    this.clearError()
  }

}
