import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Subject } from 'rxjs'

import { LogQueryParameters } from '../../models/LogQueryParameters'

@Component({
  selector: 'app-admin-log-filter',
  templateUrl: './admin-log-filter.component.html',
  styleUrls: ['./admin-log-filter.component.scss']
})
export class AdminLogFilterComponent implements OnInit {
  queryParameters: LogQueryParameters;
  @Output() newQuery: EventEmitter<LogQueryParameters> = new EventEmitter();
  @Input() parameterSetter: Subject<{parameter: string, value: string }>;

  constructor() { }

  ngOnInit(): void {
    this.resetQueryParameters()

    this.parameterSetter.subscribe(setter => {      
      if(setter.parameter === "request")
        this.queryParameters.request = setter.value;
      if(setter.parameter === "user")
        this.queryParameters.user = setter.value;
      else if(setter.parameter === "entity")
        this.queryParameters.entity = setter.value;
      else if(setter.parameter === "source")
        this.queryParameters.source = setter.value;

      this.onSubmit()
    })

    this.onSubmit()
  }

  resetQueryParameters() {
    let now = new Date()
    let nowDate = {year: now.getUTCFullYear(), month: now.getUTCMonth() + 1, day: now.getUTCDate()}
    this.queryParameters = {
      dateFrom: nowDate,
      dateUntil: nowDate,
      level: 1,
      request: '',
      user: '',
      entity: '',
      source: '',
      message: '',
      exception: ''
    }
  }

  onSubmit(){
    console.log(this.queryParameters);
    this.newQuery.emit(this.queryParameters);
  }

}
